@tailwind base;
@tailwind components;
@tailwind utilities;

main { @apply text-lg; }
main p { @apply pb-5; }

:root { color-scheme: dark; }

main h6 { @apply py-7 font-bold text-xl; }
main h5 { @apply py-7 font-bold text-xl; }
main h4 { @apply py-7 font-bold text-2xl; }
main h3 { @apply py-7 font-bold text-2xl; }
main h2 { @apply py-7 font-bold text-3xl; }
main h1 { @apply py-7 font-bold text-4xl; }

.highlight { @apply shadow-xl rounded-lg my-5; }
pre { @apply py-5 px-10 rounded-lg overflow-auto;}

blockquote { @apply ml-16 pl-6 border-l-8 border-nord2; }

table { @apply w-full text-left border-collapse; }
th { @apply font-bold; }
th, td { @apply py-2 border-b border-nord2 }
table>tbody>tr:hover { @apply bg-nord3 text-nord6; }

main ol { @apply list-decimal list-inside pl-5; }
main ul { @apply list-disc list-inside pl-5; }

main a { @apply text-nord4 bg-nord2; }
main a:hover { @apply text-nord5 bg-nord3; }

.footnotes { @apply mt-20 text-sm; }
.footnotes li { @apply flex pt-2; }

.pagination { @apply flex place-content-center text-lg; }
.pagination ul { @apply flex list-none; }
.pagination a { @apply px-4 py-2 mx-2; }
